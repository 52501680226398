import { createMuiTheme } from '@material-ui/core/styles'

const pallette = {
  primary: {
    main: '#895DD1',
    dark: '#4F6AE8',
  },
  secondary: {
    main: '#EAFF2E',
  },
  text: {
    primary: '#000',
    secondary: '#585858',
    disabled: '#fff',
  },
}

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontFamily: 'Space Grotesk',
      fontWeight: 700,
      fontSize: '72px',
      letterSpacing: '2px',
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: 'Space Grotesk',
      fontWeight: 700,
      fontSize: '36px',
      letterSpacing: '2px',
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: 'Inter',
      fontWeight: 700,
      fontSize: '36px',
      letterSpacing: '0px',
    },
    h5: {
      fontFamily: 'Space Grotesk',
      fontSize: '24px',
      fontWeight: 900,
    },
    h6: {
      fontFamily: 'Inter',
      fontSize: '24px',
      fontWeight: 900,
    },
    subtitle1: {
      fontFamily: 'Space Grotesk',
      fontSize: '12px',
      fontWeight: 700,
      color: '#585858',
      lineHeight: 1.2,
      letterSpacing: '2px',
      '-webkit-font-smoothing': 'antialiased',
    },
    subtitle2: {
      fontSize: '18px',
      fontFamily: 'Space Grotesk',
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: '0px',
      color: '#fff',
    },
    body1: {
      fontSize: '18px',
      color: '#555555',
      lineHeight: '1.8em',
    },
    body2: {
      fontSize: '18px',
    },
  },
  palette: {
    ...pallette,
  },
  overrides: {
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover': {
          borderColor: '#895DD1 !important',
          opacity: 0.8,
          transition: 'opacity .2s ease-out',
          '-moz-transition': 'opacity .2s ease-out',
          '-webkit-transition': 'opacity .2s ease-out',
          '-o-transition': 'opacity .2s ease-out',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#895DD1 !important',
          opacity: 0.8,
          transition: 'opacity .2s ease-out',
          '-moz-transition': 'opacity .2s ease-out',
          '-webkit-transition': 'opacity .2s ease-out',
          '-o-transition': 'opacity .2s ease-out',
        },
      },
      notchedOutline: {
        borderWidth: '2px',
        borderColor: '#895DD1',
        borderRadius: '0px',
        '&:hover': {
          borderColor: '#895DD1 !important',
          opacity: 0.8,
          transition: 'opacity .2s ease-out',
          '-moz-transition': 'opacity .2s ease-out',
          '-webkit-transition': 'opacity .2s ease-out',
          '-o-transition': 'opacity .2s ease-out',
        },
      },
    },
    MuiButton: {
      root: {
        color: 'white',
        fontFamily: 'Inter',
      },
      containedPrimary: {
        backgroundColor: pallette.primary.main,
        '&:hover': {
          opacity: 0.7,
          cursor: 'pointer',
          backgroundColor: pallette.primary.main,
        },
      },
      containedSecondary: {},
    },
    MuiAccordion: {
      root: {
        boxShadow: 'none',
        border: '2px solid #895DD1',
      },
    },
  },
})

export default theme
