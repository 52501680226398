import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import App from 'next/app'
import Router from 'next/router'
import * as Sentry from '@sentry/browser'
import NProgress from 'nprogress'
import { AmplitudeClient, Identify } from 'amplitude-js'
import { Amplitude, AmplitudeProvider } from '@amplitude/react-amplitude'
import { Web3ContextProvider } from '../src/contexts/Web3Context'
import Head from 'next/head'

import theme from '../src/utils/theme'

import '../public/transitions.css'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NODE_ENV,
})

Router.events.on('routeChangeStart', (url) => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

class MyApp extends App {
  componentDidMount(): void {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles)
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key as keyof typeof errorInfo])
      })

      Sentry.captureException(error)
    })

    super.componentDidCatch(error, errorInfo)
  }

  render(): JSX.Element {
    const { Component, pageProps } = this.props
    const isBrowser = typeof window !== 'undefined'

    if (isBrowser) {
      const amplitude = require('amplitude-js') // eslint-disable-line @typescript-eslint/no-var-requires
      const amplitudeInstance: AmplitudeClient = amplitude.getInstance()

      amplitudeInstance.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || '', '', {
        logLevel: process.env.NODE_ENV === 'production' ? 'DISABLE' : 'WARN',
        includeGclid: true,
        includeReferrer: true,
        includeUtm: true,
      })

      if (amplitudeInstance.isNewSession()) {
        const visitor: Identify = new amplitudeInstance.Identify()
        visitor.setOnce('lang', pageProps.lang)
        amplitudeInstance.identify(visitor)
      }

      return (
        <AmplitudeProvider
          amplitudeInstance={amplitudeInstance}
          apiKey={process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY}
        >
          <Amplitude
            eventProperties={{
              page: {
                url: location.href,
                path: location.pathname,
                origin: location.origin,
                name: location.pathname,
              },
            }}
          >
            <React.Fragment>
              <Web3ContextProvider>
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Head>
                    <meta
                      name="viewport"
                      content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                    />
                  </Head>
                  <Component {...pageProps} />
                </MuiThemeProvider>
              </Web3ContextProvider>
            </React.Fragment>
          </Amplitude>
        </AmplitudeProvider>
      )
    } else {
      return (
        <>
          <React.Fragment>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <Head>
                <meta
                  name="viewport"
                  content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                />
              </Head>
              <Component {...pageProps} />
            </MuiThemeProvider>
          </React.Fragment>
        </>
      )
    }
  }
}

export default MyApp
